import './Layout.scss';
import Nav from "./Nav";

function Layout({children}) {
    return (
        <div className="Layout">
            <Nav/>
            <div className="Layout_Wrapper">
                {children}
            </div>
        </div>
    );
}

export default Layout;
