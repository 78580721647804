import './Home.scss';
import main_tank from '../../assets/main_tank.png';
import hatch_sensor_up_active from '../../assets/hatch_sensor_up_active.png';
import hatch_sensor_down_active from '../../assets/hatch_sensor_down_active.png';
import mixer_1_running from '../../assets/mixer_1_running.png';
import mixer_2_running from '../../assets/mixer_2_running.png';
import mixer_3_running from '../../assets/mixer_3_running.png';
import mixer_1_error from '../../assets/mixer_1_error.png';
import mixer_2_error from '../../assets/mixer_2_error.png';
import mixer_3_error from '../../assets/mixer_3_error.png';
import mixer_1_warning from '../../assets/mixer_1_warning.png';
import mixer_2_warning from '../../assets/mixer_2_warning.png';
import mixer_3_warning from '../../assets/mixer_3_warning.png';
import React, {useEffect, useState} from "react";
import axios from "axios";
import DataTable from "./DataTable";
import FunctionsTable from "./FunctionsTable";

function Home() {
    const [refresh, setRefresh] = useState(0);
    const [historicalData, setHistoricalData] = useState({
        compressor: false,
        hatch_sensor_up: false,
        hatch_sensor_down: false,
        level_switch_1: false,
        level_switch_2: false,
        mixer_1: "0.00 %",
        mixer_2: "0.00 %",
        mixer_3: "0.00 %",
        pressure_sensor_1: "0.0 bar",
        pressure_sensor_2: "0.0 bar",
        pump: false,
        temperature_sensor_1: "0.0 °C",
        temperature_sensor_2: "0.0 °C"
    });

    const [functions, setFunctions] = useState({
        mixing: "ready",
        homogenization: "ready",
    })

    const [mixersAlarms, setMixersAlarms] = useState({
        mixer_1: {
            warning: false,
            error: false,
        },
        mixer_2: {
            warning: false,
            error: false,
        },
        mixer_3: {
            warning: false,
            error: false,
        }
    });


    const fetchFunctionInfo = async () => {
        const {data} = await axios.request({
            url: `/functions`,
        })
        if (data.functions) {
            setFunctions(data.functions)
        }
    }

    const fetchHistoricalData = async () => {
        const {data} = await axios.request({
            url: `/historical_data_logs`,
        })

        if (data.historical_data) {
            setHistoricalData(data.historical_data)
        }
        if (data.mixers_alarms) {
            setMixersAlarms(data.mixers_alarms)
        }
    }

    useEffect(() => {
        fetchFunctionInfo()
        fetchHistoricalData()
        const timer = setTimeout(() => setRefresh(refresh + 1), process.env.REACT_APP_REFRESH_INTERVAL);

        return () => clearTimeout(timer)
    }, [refresh]);

    return (
        <div className="Home">
            <div className="Home_Tables">
                <FunctionsTable data={functions}/>
                <DataTable data={historicalData}/>
            </div>
            <div className='Home_Tank'>
                <img className='Home_Tank_MainImageBackground' alt='Mixer' src={main_tank}/>
                <img className='Home_Tank_BasicImagePosition' alt='Mixer' src={main_tank}/>
                {historicalData.hatch_sensor_up &&
                    <img className='Home_Tank_BasicImagePosition' alt='Hatch sensor up'
                         src={hatch_sensor_up_active}/>}
                {historicalData.hatch_sensor_down &&
                    <img className='Home_Tank_BasicImagePosition' alt='Hatch sensor down'
                         src={hatch_sensor_down_active}/>}
                {historicalData.mixer_1 !== '0.00 %' &&
                    <img className='Home_Tank_BasicImagePosition' alt='Mixer 1 Running'
                         src={mixer_1_running}/>}
                {historicalData.mixer_2 !== '0.00 %' &&
                    <img className='Home_Tank_BasicImagePosition' alt='Mixer 2 Running'
                         src={mixer_2_running}/>}
                {historicalData.mixer_3 !== '0.00 %' &&
                    <img className='Home_Tank_BasicImagePosition' alt='Mixer 3 Running'
                         src={mixer_3_running}/>}
                {mixersAlarms.mixer_1.warning &&
                    <img className='Home_Tank_BasicImagePosition' alt='Mixer 1 Warining'
                         src={mixer_1_warning}/>}
                {mixersAlarms.mixer_2.warning &&
                    <img className='Home_Tank_BasicImagePosition' alt='Mixer 2 Warining'
                         src={mixer_2_warning}/>}
                {mixersAlarms.mixer_3.warning &&
                    <img className='Home_Tank_BasicImagePosition' alt='Mixer 3 Warining'
                         src={mixer_3_warning}/>}
                {mixersAlarms.mixer_1.error &&
                    <img className='Home_Tank_BasicImagePosition' alt='Mixer 1 Error'
                         src={mixer_1_error}/>}
                {mixersAlarms.mixer_2.error &&
                    <img className='Home_Tank_BasicImagePosition' alt='Mixer 2 Error'
                         src={mixer_2_error}/>}
                {mixersAlarms.mixer_3.error &&
                    <img className='Home_Tank_BasicImagePosition' alt='Mixer 3 Error'
                         src={mixer_3_error}/>}
                <span className='Home_Tank_Mixer1RPM'>{historicalData.mixer_1}</span>
                <span className='Home_Tank_Mixer2RPM'>{historicalData.mixer_2}</span>
                <span className='Home_Tank_Mixer3RPM'>{historicalData.mixer_3}</span>
                <span className='Home_Tank_Pressure'>{historicalData.pressure_sensor_1}</span>
                <span className='Home_Tank_Temperature'>{historicalData.temperature_sensor_1}</span>
            </div>
        </div>
    );
}

export default Home;
