import './Logs.scss';

function Logs() {
    return (
        <div className="Logs">
            Logs
        </div>
    );
}

export default Logs;
