import './Charts.scss';
import axios from "axios";
import {useEffect, useState} from "react";
import TemperaturesChart from "./TemperaturesChart";
import moment from "moment/moment";
import MixersChart from "./MixersChart";
import PressuresChart from "./PressuresChart";
import PumpAndCompressorChart from "./PumpAndCompressorChart";

const Charts = () => {
    const [refresh, setRefresh] = useState(0);
    const [chartsData, setChartsData] = useState({
        data_x: [],
        data_y: {
            hatch_sensor_up: [],
            hatch_sensor_down: [],
            mixer_1: [],
            mixer_2: [],
            mixer_3: [],
            temperature_sensor_1: [],
            pressure_sensor_1: [],
            compressor: [],
            pump: [],
            level_switch_1: [],
            level_switch_2: [],
            pressure_sensor_2: [],
            temperature_sensor_2: []
        },
    });
    const fetchChartsData = async () => {
        const {data} = await axios.request({
            url: `/charts_data`,
        })
        setChartsData(data.charts_data)
    }

    useEffect(() => {
        fetchChartsData()
        const timer = setTimeout(() => setRefresh(refresh + 1), process.env.REACT_APP_REFRESH_INTERVAL);

        return () => clearTimeout(timer)
    }, [refresh]);

    const labels = chartsData.data_x.map((x, i) => moment(x).format('HH:mm')) || []

    return (
        <div className="Charts">
            <TemperaturesChart chartsData={chartsData} labels={labels}/>
            <MixersChart chartsData={chartsData} labels={labels}/>
            <PressuresChart chartsData={chartsData} labels={labels}/>
            <PumpAndCompressorChart chartsData={chartsData} labels={labels}/>
        </div>
    );
}

export default Charts;
