import React from 'react';
import {Table} from 'antd';

const DataTable = ({data}) => {
    const dataTable = [
        {key: 'compressor', name: 'Kompresor', value: data.compressor ? 'Włączony' : 'Wyłączony'},
        {key: 'level_switch_1', name: 'Max poziom naczynia zaporowego', value: data.level_switch_1 ? 'Osiągnięty' : 'Nieosiągnięty'},
        {key: 'level_switch_2', name: 'Min poziom naczynia zaporowego', value: data.level_switch_2 ? 'Osiągnięty' : 'Nieosiągnięty'},
        {key: 'pressure_sensor_2', name: 'Ciśnienie w naczyniu zaporowym', value: data.pressure_sensor_2},
        {key: 'pump', name: 'Pompa', value: data.pump ? 'Włączony' : 'Wyłączony'},
        {key: 'temperature_sensor_2', name: 'Temperatura naczynia zaporowego', value: data.temperature_sensor_2}
    ]

    const columns = [
        {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Odczyt',
            dataIndex: 'value',
            key: 'value',
        },
    ];

    return (
        <div className="Home_Table">
            <h3>Dodatkowe wskazania:</h3>
            <Table columns={columns} dataSource={dataTable} pagination={{position: ['none']}}/>
        </div>
    )
};
export default DataTable;