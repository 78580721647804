import React from 'react';
import {ContainerOutlined, DesktopOutlined, PieChartOutlined, WarningOutlined,} from '@ant-design/icons';
import {Menu} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';


const Nav = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const items = [
        {
            key: '/',
            icon: <DesktopOutlined/>,
            label: 'Home',
        },
        {
            key: '/alarms',
            icon: <WarningOutlined/>,
            label: 'Alarms',
        },
        {
            key: '/charts',
            icon: <PieChartOutlined/>,
            label: 'Charts',
        },
        // {
        //     key: '/logs',
        //     icon: <ContainerOutlined/>,
        //     label: 'Reports',
        // }
    ];

    return (
        <div className="Nav"
        >
            <Menu
                defaultSelectedKeys={[location.pathname]}
                mode="horizontal"
                // theme="dark"
                items={items}
                onClick={({key}) => {
                    navigate(key)
                }}
            />
        </div>
    );
};
export default Nav;