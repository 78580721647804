import React, {useEffect, useState} from 'react';
import axios from "axios"
import './Alarms.scss';
import {Table} from 'antd';
import moment from "moment";

const Alarms = () => {
    const [refresh, setRefresh] = useState(0);
    const [alarms, setAlarms] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const columns = [
        {
            title: 'Opis',
            dataIndex: 'description',
            key: 'description',
            minWidth: '40%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Czas wystąpienia',
            dataIndex: 'raise_time',
            key: 'raise_time',
            render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss')
        },
        // {
        //     title: 'Unikalna nazwa',
        //     dataIndex: 'name',
        //     key: 'name',
        // },
    ];

    const fetchAlarms = async () => {
        const {data} = await axios.request({
            url: `/alarms`,
        })
        setAlarms(data.alarms)
        setTotalCount(data.total)
    }

    useEffect(() => {
        fetchAlarms()
        const timer = setTimeout(() => setRefresh(refresh + 1), process.env.REACT_APP_REFRESH_INTERVAL);

        return () => clearTimeout(timer)
    }, [refresh]);

    return (
        <div className="Alarms">
            <h3>Alarmy</h3>
            <Table
                className="Alarms_Table"
                pagination={{
                    position: ['bottomCenter'],
                    total: totalCount,
                }}
                columns={columns}
                dataSource={alarms}
                rowClassName={(record) => {
                    if (record.status === 'alarm') {
                        return 'alarm'
                    } else if (record.status === 'warning') {
                        return 'warning'
                    }
                }}
                scroll={{
                    x: "100%",
                }}
            />
        </div>
    )
};
export default Alarms;