import './Charts.scss';
import {Line} from "react-chartjs-2";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const MixersCharts = ({chartsData, labels}) => {
    const options = {
        radius: 0,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Prędkość silników',
            },
        },
    }

    const data = {
        labels,
        datasets: [
            {
                label: '1-M-001',
                data: chartsData.data_y.mixer_1 || [],
                borderColor: 'rgb(0, 171, 120)',
                backgroundColor: 'rgba(0,171,120, 0.5)',
                borderWidth: 1,
            },
            {
                label: '1-M-002',
                data: chartsData.data_y.mixer_2 || [],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                borderWidth: 1,
            },
            {
                label: '1-M-003',
                data: chartsData.data_y.mixer_3 || [],
                borderColor: 'rgb(250, 158, 20)',
                backgroundColor: 'rgba(250, 158, 20, 0.5)',
                borderWidth: 1,
            },
        ],
    }

    return (
        <Line options={options} data={data}/>
    );
}

export default MixersCharts;
