import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from "./views/Home/Home";
import Layout from "./Layout/Layout";
import Alarms from "./views/Alarms/Alarms";
import Charts from "./views/Charts/Charts";
import Logs from "./views/Logs/Logs";
import {setupAxios} from "./axios";
import HttpsRedirect from 'react-https-redirect';

function App() {
    setupAxios()
    return (
        <HttpsRedirect>
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/alarms" element={<Alarms/>}/>
                    <Route path="/charts" element={<Charts/>}/>
                    <Route path="/logs" element={<Logs/>}/>
                </Routes>
            </Layout>
        </Router>
        </HttpsRedirect>
    )
}

export default App;
