import React from 'react';
import {Table} from 'antd';

const FunctionsTable = ({data}) => {
    const displayName = (status) => {
        switch (status) {
            case 'error':
                return 'Błąd'
            case 'ready':
                return 'Gotowy'
            case 'running':
                return 'Uruchomiony'
            case 'paused':
                return 'Zatrzymany'
            default:
                return 'Nieznany'
        }
    }

    const dataTable = [
        {key: 'mixing', name: 'Mieszanie', status: displayName(data.mixing), status_key: data.mixing},
        {key: 'homogenization', name: 'Homogenizacja', status: displayName(data.homogenization), status_key: data.homogenization},
    ]

    const columns = [
        {
            title: 'Nazwa procesu',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    return (
        <div className="Home_Tables_Functions">
            <h3>Proces produkcji:</h3>
            <Table
                columns={columns}
                dataSource={dataTable}
                rowClassName={(record) => record.status_key}
                pagination={{position: ['none']}}/>
        </div>
    )
};
export default FunctionsTable;