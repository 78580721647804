import './Charts.scss';
import {Line} from "react-chartjs-2";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const PumpAndCompressorChart = ({chartsData, labels}) => {
    const options = {
        radius: 0,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Pompa i kompresor',
            },
        },
    }

    const data = {
        labels,
        datasets: [
            {
                label: 'Pompa',
                data: chartsData.data_y.pump || [],
                borderColor: 'rgb(0,171,120)',
                backgroundColor: 'rgba(0,171,120, 0.5)',
                borderWidth: 1,
            },
            {
                label: 'Kompresor',
                data: chartsData.data_y.compressor || [],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                borderWidth: 1,
            },
        ],
    }

    return (
        <Line options={options} data={data}/>
    );
}

export default PumpAndCompressorChart;
